/*
 * @Author: 姚文静
 * @Date: 2024-10-29 14:25:06
 * @LastEditTime: 2024-10-30 16:37:30
 * @LastEditors: 姚文静
 * @Description: 
 * @FilePath: /snowTee-pc/src/utils/auth.ts
 */
import crypto from 'crypto'
export function genHeader() {


  function hmacSha256(data: string, secret: string) {
    return crypto.createHmac('sha256', secret).update(data).digest();
  }

  function base64Encode(buffer: Buffer) {
    return buffer.toString('base64');
  }
  // 原始数据
  const content = "key-auth: ym-snowteepc-gkbmId";
  // console.log("签名前的数据：" + content);
  // 密钥
  const secret = "HUUBGbyeVuc21JpS";
  const keyAuth = 'ym-snowteepc-gkbmId';
  // get GMT tim
  const _Date: string = new Date().toUTCString();
  // -H "Date: Fri, 13 Sep 2024 02:01:55 GMT" \
  const signature = base64Encode(hmacSha256(content, secret));
  const proxyAuthorization = `hmac  username="ym-snowteepc-gkbmId", algorithm="hmac-sha256", headers="key-auth", signature="${signature}"`
  const userInfo = localStorage.getItem('userInfo') || '{}'
  
  return {
    'x-date': _Date,
    "authorization": proxyAuthorization,
    "key-auth": keyAuth,
    "appBrandKey": " ym-snowteepc-web"
  }
}