/*
 * @Author: 姚文静
 * @Date: 2024-10-28 16:56:21
 * @LastEditTime: 2024-10-30 11:27:12
 * @LastEditors: 姚文静
 * @Description:
 * @FilePath: /snowTee-pc/src/app.ts
 */
// 运行时配置

// 全局初始化数据配置，用于 Layout 用户信息和权限初始化
// 更多信息见文档：https://umijs.org/docs/api/runtime-config#getinitialstate
import type { RequestConfig } from '@umijs/max';
import { errorConfig } from './requestErrorConfig';
/**
 * @name request 配置，可以配置错误处理
 * 它基于 axios 和 ahooks 的 useRequest 提供了一套统一的网络请求和错误处理方案。
 * @doc https://umijs.org/docs/max/request#配置
 */
export const request: RequestConfig = {
  requestInterceptors: [],
  ...errorConfig,
};
