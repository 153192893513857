/*
 * @Author: 姚文静
 * @Date: 2024-09-13 16:12:21
 * @LastEditTime: 2024-10-31 14:33:45
 * @LastEditors: 姚文静
 * @Description: 
 * @FilePath: /snowTee-pc/src/utils/constant.ts
 */

export const ACCESS_TOKEN: string = 'accessToken';
export const USER_INFO: string = 'user_info';
export const TOKEN_TIME: string = 'token_time';
export const realmName: string = 'www.huayangxiaozhan.com';
export const CDN: string = 'https://sfs-public.shangdejigou.cn/sunlands_back_freestudy/fe/study';
export const JX_TITLE: string = '及象运营平台';
export const HY_TITLE: string = '花样运营平台';
export const JX_LOGO: string = `${CDN}/jx-header-logo.png`;
export const HY_LOGO: string = `${CDN}/hy-header-logo.png`;
export const BRANDID: string = 'brandId';//默认brandId
export const TRACE: string = '/actionInfo/add';

export type URLTYPES = 'baseurl' | '/actionInfo/add';
export const LAYOUT_TYPE_HORIZONTAL = 'horizontal'
console.log( REACT_APP_ENV, 'REACT_APP_ENV')
// REACT_APP_ENV
let env: keyof IURLS;

if (REACT_APP_ENV === 'test') {
  env = 'development';
} else if (REACT_APP_ENV === 'dev') {
  env = 'development';
} else { 
  env = 'prod'
}
interface IURLS {
  development: {
    [key in URLTYPES]: string;
  };
  prod: {
    [key in URLTYPES]: string;
  };
}
const URLS: IURLS = {
  development: {
    baseurl: 'https://test-gw.snowtee.net',
    '/actionInfo/add': 'https://test-cloud-bf.dayustudy.com',
  },
  prod: {
    baseurl: 'https://gw.snowtee.net', //  线上
    '/actionInfo/add': 'https://data-trace.dayustudy.com',
  },
};
export const _URL = URLS[env];
console.log('URLS', _URL);
// export const UPLOADURL: string = `${_URL.baseurl}/tencent/oss/upload2FixedFileName`;
// export const UPLOADVIDEOURL: string = `${_URL.baseurl}/tencentApi/vod/upload`;
// export const ORDERDOWNURL: string = `${_URL.baseurl}`

// export const WEBBASE = process.env.NODE_ENV === 'production'? '' : ''

